import React from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { ButtonStyled } from './styles';
import bvStyleguide from '@bv/styleguide';

const { Loader } = bvStyleguide;

type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

export function Button({ children, loading, disabled, ...props }: TButtonProps) {
  return (
    <ButtonStyled {...props} disabled={disabled || loading}>
      {loading && <Loader className="h-5 w-5 text-[#a9a9a9]" />}
      {children}
    </ButtonStyled>
  );
}
