import * as colors from '../../../../shared/styles/colors';
import styled from 'styled-components';

export const ButtonStyled = styled.button`
  background-color: ${colors.blue300};
  color: white;
  padding: 10px 32px;
  border-radius: 8px;
  gap: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.7;
    border: 2px solid #eaecf0 !important;
    background-color: #f9fafb !important;
    color: #a9a9a9 !important;
  }
`;
