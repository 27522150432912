import { api } from './api';

export type TCompanyValidationResponse = {
  displayName: string;
  brand: string;
  payload: {
    companyId: string;
    redirectUrl: string;
    prescriptionPath: string | ArrayBuffer;
    email: string;
    birthdate: string;
    name: string;
    cpf: string;
    priority?: 'VERY_HIGH' | 'HIGH' | 'MEDIUM' | 'LOW';
    partnerParams?: object;
  };
};

export type TPostEmailValidation = {
  email: string;
};

export type TGetValidateOTPResponse = {
  accessToken?: string;
};

export type TGetValidateOTP = {
  email: string;
  code: string;
  cpf: string;
  companyId: string;
};

export type TPostCreatePatient = {
  companyId: string;
  name: string;
  cpf: string;
  birthdate: string;
  email: string;
  acceptedTerms: boolean;
  gender: 'M' | 'F';
  code: string;
};

export type TPostCreatePatientResponse = {
  accessToken: string;
};

const getCompanyValidationService = (token: string): Promise<TCompanyValidationResponse> => {
  return api.get(`/v2/companies/secrets/validate?&token=${token}`);
};

const postEmailValidationService = (body: TPostEmailValidation) => {
  return api.post(`/v2/users/otp`, body);
};

const getValidateOTPService = (body: TGetValidateOTP): Promise<TGetValidateOTPResponse> => {
  return api.get(`/v2/users/otp/check`, { params: body });
};

const postCreatePatientService = (body: TPostCreatePatient): Promise<TPostCreatePatientResponse> => {
  return api.post(`v2/users/otp/user`, body);
};

export { getCompanyValidationService, postEmailValidationService, getValidateOTPService, postCreatePatientService };
