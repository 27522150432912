import axios from 'axios';
import bvUtils from '@bv/utils';

export const api = axios.create({
  baseURL: process.env.BV_API_URL,
});

api.interceptors.request.use(function (config) {
  const storageToken = localStorage.getItem('prescriptionToken');
  const ACCESS_TOKEN: string | null = storageToken ? JSON.parse(storageToken) : null;

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  return config;
});

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    if (err.response && [401, 403].includes(+err.response.status)) {
      bvUtils.cookies.clearAuthCookies();
      window.localStorage.clear();
      window.location.href = '/otica/trocar-receita/tour' + window.location.search;
    } else if (err.response && err.response.status === 406) {
      bvUtils.cookies.clearAuthCookies();
      window.localStorage.clear();
      return (window.location.href = '/otica/trocar-receita/aviso/aguardando-avaliacao' + window.location.search);
    }

    console.log({ err });

    if (err?.response?.data?.messages) {
      bvUtils.request.requestErrorToastHandler(err.response.data.messages);
      return Promise.reject(err.response.data.messages);
    }

    if (err?.response?.data?.message) {
      bvUtils.request.requestErrorToastHandler(err.response.data.message);
      return Promise.reject(err.response.data.message);
    }

    return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
  },
);
