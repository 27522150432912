import { ContainerStyled } from "@optics-exchange-prescription-old/styled/base.styled";
import styled from "styled-components";

export const WarningWithoutPrescriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > ${ContainerStyled} {
    padding: 0 48px;
  }
`;
