import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BVStyleguide from '@bv/styleguide';
import { getPrescriptionService, TGetPerscription } from '../../services/prescription.service';
import { BodyTextStyled, ContainerStyled, TitleStyled } from '@optics-exchange-prescription-old/styled/base.styled';
import { FileDown } from 'lucide-react';
import { Button } from '../../components/Button';
import bvUtils from '@bv/utils';

export default function MyPrescription() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<TGetPerscription[]>([]);

  const id = searchParams.get('id');

  if (!id) navigate('/pagina-nao-encontrada');

  async function downloadFile(url: string, fileName: string) {
    console.log({ fileName });
    try {
      const response = await fetch(url); // Faz o fetch do arquivo
      const blob = await response.blob(); // Converte para blob

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob); // Cria uma URL de objeto a partir do Blob
      link.setAttribute('download', `${fileName}.pdf`); // Define o nome do arquivo
      document.body.appendChild(link);
      link.click(); // Simula o clique para o download
      link.remove(); // Remove o link do DOM
    } catch (error) {
      bvUtils.toastHandler({ type: 'error', message: 'Erro ao baixar o arquivo' });
      console.error('Erro ao baixar o arquivo:', error);
    }
  }

  useEffect(() => {
    async function getPrescription() {
      try {
        setLoading(true);
        const response = await getPrescriptionService(id);
        setDocuments(response);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        navigate('/pagina-nao-encontrada');
      }
    }

    if (id) getPrescription();
  }, [id]);

  if (loading) return <BVStyleguide.PageLoaderEyecare />;

  return (
    <div className="mx-auto flex h-screen w-full max-w-[468px] flex-col p-8">
      <div className="flex flex-1 flex-col justify-center">
        <TitleStyled>Minha receita</TitleStyled>
        <BodyTextStyled>Sua receita foi aprovada e você pode baixá-la clicando no(s) botão(ões) de download abaixo.</BodyTextStyled>
        <div className="mt-4 space-y-4">
          {documents.map((document, index) => (
            <button
              onClick={() => downloadFile(document.fileUrl, document.fileName)}
              key={document.fileName + index}
              className="text-primary flex items-center gap-2 font-semibold no-underline">
              <FileDown size={18} />
              {document.fileName}
            </button>
          ))}
        </div>
      </div>
      <Button
        className="w-full max-w-[468px]"
        onClick={() => {
          window.location.replace('about:blank');
        }}>
        Concluir
      </Button>
    </div>
  );
}
