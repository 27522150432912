import * as colors from '../../../shared/styles/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const LayoutStyled = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: ${colors.white};
  left: 0;
  top: 0;
  z-index: 10;
  overflow-y: auto;
`;

export const ContainerStyled = styled.div`
  width: 100%;
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0;
    margin: 0 auto;
    max-width: 468px;
  }
`;

export const TitleStyled = styled.h2`
  font-weight: 700;
  color: ${colors.gray900};
  margin-bottom: 16px;
  font-size: 32px;
  font-family: 'Ubuntu', sans-serif;
`;

export const BodyTextStyled = styled.p`
  color: ${colors.gray600};
  line-height: 24px;
  strong {
    font-weight: 700;
  }
`;

export const PrescriptionExchangeGlobalStyle = createGlobalStyle`
  html, body {
    background-color: #F7F7F7;
  }
`;
