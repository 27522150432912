import BVStyleguide from '@bv/styleguide';
import { useSetAtom } from 'jotai';
import { lazy, Suspense, useEffect, useState } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { prescriptionUserAtom } from './atom/auth.atom';
import { getCompanyValidationService } from './services/auth.service';
import { LayoutStyled, PrescriptionExchangeGlobalStyle } from './styled/base.styled';
import MyPrescription from './pages/MyPrescription';
import ErrorWithoutPrescriptionData from './pages/ErrorWithoutPrescriptionData';
import bvUtils from '@bv/utils';
const Splash = lazy(() => import('./pages/SplashScreen'));
const Tour = lazy(() => import('./pages/Tour'));
const WarningBeforeToStart = lazy(() => import('./pages/WarningBeforeToStart'));
const Login = lazy(() => import('./pages/Login'));
const OTPValidation = lazy(() => import('./pages/OTPValidation'));
const Register = lazy(() => import('./pages/Register'));
const ErrorCantProceed = lazy(() => import('./pages/ErrorCantProceed'));
const UploadPrescription = lazy(() => import('./pages/UploadPrescription'));
const WarningWithoutPrescription = lazy(() => import('./pages/WarningWithoutPrescription'));
const ErrorGoToDoctor = lazy(() => import('./pages/ErrorGoToDoctor'));
const AwaitingEvaluation = lazy(() => import('./pages/AwaitingEvaluation'));
const Success = lazy(() => import('./pages/Success'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));

//trigger pipeline

const router = createBrowserRouter(
  [
    {
      element: (
        <Suspense fallback={<BVStyleguide.PageLoaderEyecare />}>
          <LayoutStyled>
            <Outlet />
          </LayoutStyled>
        </Suspense>
      ),
      children: [
        {
          path: '/',
          element: <Splash />,
        },
        {
          path: '/tour',
          element: <Tour />,
        },
        {
          path: '/antes-de-comecar',
          element: <WarningBeforeToStart />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/validar-otp',
          element: <OTPValidation />,
        },
        {
          path: '/cadastro',
          element: <Register />,
        },
        {
          path: '/erro/nao-pode-prosseguir',
          element: <ErrorCantProceed />,
        },
        {
          path: '/upload-prescricao',
          element: <UploadPrescription />,
        },
        {
          path: '/upload-prescricao/aviso/sem-prescricao',
          element: <WarningWithoutPrescription />,
        },
        {
          path: '/aviso/aguardando-avaliacao',
          element: <AwaitingEvaluation />,
        },
        {
          path: '/erro/ir-ao-medico',
          element: <ErrorGoToDoctor />,
        },
        {
          path: '/erro/sem-dados-receita',
          element: <ErrorWithoutPrescriptionData />,
        },
        {
          path: '/sucesso',
          element: <Success />,
        },
        {
          path: '/minha-receita',
          element: <MyPrescription />,
        },
        {
          path: '/pagina-nao-encontrada',
          element: <PageNotFound />,
        },
        {
          path: '/*',
          element: <PageNotFound />,
        },
      ],
    },
  ],
  {
    basename: '/otica/trocar-receita',
  },
);

export default function App(props) {
  const [loading, setLoading] = useState(false);
  const setUser = useSetAtom(prescriptionUserAtom);
  const authToken = bvUtils.cookies.getAccessToken();
  // const prescriptionToken = useAtomValue(prescriptionTokenAtom);
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname;

  useEffect(() => {
    setLoading(true);
    async function validateCompany() {
      const urlToken = searchParams.get('token');

      try {
        if (['/otica/trocar-receita/pagina-nao-encontrada', '/otica/trocar-receita/minha-receita'].includes(pathname)) {
          return setLoading(false);
        }
        if (!urlToken) {
          return singleSpa.navigateToUrl('/otica/trocar-receita/pagina-nao-encontrada');
        }

        const response = await getCompanyValidationService(urlToken);

        setUser(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        singleSpa.navigateToUrl('/otica/trocar-receita/pagina-nao-encontrada');
      }
    }

    if (!authToken) validateCompany();
    else setLoading(false);
  }, []);

  if (loading) {
    return <BVStyleguide.PageLoaderEyecare />;
  }

  return (
    <LayoutStyled>
      <PrescriptionExchangeGlobalStyle />
      <RouterProvider router={router} />
    </LayoutStyled>
  );
}
