import { api } from './api';

export type TGetPerscription = {
  fileName: string;
  fileUrl: string;
};

async function getPrescriptionService(id: string): Promise<TGetPerscription[]> {
  return api.get(`/pendencies/prescription-renewal/download`, { params: { id } });
}

export { getPrescriptionService };
