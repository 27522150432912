import { TCompanyValidationResponse } from '../services/auth.service';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const prescriptionTokenInitialState = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')) : null;

const prescriptionUserInitialState = window.localStorage.getItem('prescriptionUser')
  ? JSON.parse(window.localStorage.getItem('prescriptionUser'))
  : null;

export const prescriptionTokenAtom = atomWithStorage('token', prescriptionTokenInitialState, localStorage);

export const prescriptionUserAtom = atomWithStorage<TCompanyValidationResponse>('prescriptionUser', prescriptionUserInitialState);

export const otpAtom = atom('');
