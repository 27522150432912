import { BodyTextStyled, ContainerStyled, TitleStyled } from '../../styled/base.styled';
import { ErrorWithoutPrescriptionDataStyled } from './styles';
import infoAsset from '../../assets/images/icons/info-circle.png';
import { useAtomValue } from 'jotai';
import { prescriptionUserAtom } from '@optics-exchange-prescription-old/atom/auth.atom';
import { Button } from '@optics-exchange-prescription-old/components/Button';
import { TCompanyValidationResponse } from '../../services/auth.service';
import * as singleSpa from 'single-spa';

export default function ErrorWithoutPrescriptionData() {
  const user = useAtomValue<TCompanyValidationResponse>(prescriptionUserAtom);

  return (
    <ErrorWithoutPrescriptionDataStyled>
      <ContainerStyled>
        <div className="upload-prescription__content">
          <img src={infoAsset} style={{ marginBottom: 16 }} />
          <TitleStyled>Por enquanto não podemos continuar.</TitleStyled>
          <BodyTextStyled>
            Não foi possível solicitar a renovação de receita, pois você não preencheu o <strong>valor do grau dos seus óculos atuais</strong>.
          </BodyTextStyled>
          <BodyTextStyled>Se desejar, você pode refazer a triagem e corrigir essas informações.</BodyTextStyled>
          <Button onClick={() => singleSpa.navigateToUrl('/triagem')} style={{ width: '100%', marginTop: 32 }}>
            Ir para triagem
          </Button>
        </div>
      </ContainerStyled>
    </ErrorWithoutPrescriptionDataStyled>
  );
}
